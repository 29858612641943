<template>

	<div class="zk-page">

		<div style="background: #fff;border-radius: 8px;border: solid 1px #efefef;">
			<div class="" style="text-align: center;padding: 20px 0;border-bottom: solid 1px #d6d6d6;">
				<p>
					<img src="/images/icon_tishi.png" style="width:42px;">
				</p>
				<p style="line-height:40px;color: #2A2A2A;font-size: 16px;">是否确认授权采集设备以下信息</p>
			</div>
			<div class="group-block">
				<zk-column-nav icon='icon-yonghuxinxi'>基本信息</zk-column-nav>
				<div class="block-info">
					<zk-form-item label="姓名">{{ info.realName }}</zk-form-item>
					<zk-form-item label="性别">{{ info.sex == 0 ?'女':'男' }}</zk-form-item>
					<zk-form-item label="年龄">{{ info.age }}</zk-form-item>
				</div>
			</div>
			<div class="group-block" style="border: none;">
				<zk-column-nav icon='icon-sangedian'>其他信息</zk-column-nav>
				<div class="block-info">
					<zk-form-item label="身份证">{{ info.cardNoStr }}</zk-form-item>
					<zk-form-item label="身高">{{ info.height || "--" }} cm</zk-form-item>
					<zk-form-item label="体重">{{ info.weight || "--" }} kg</zk-form-item>
				</div>
			</div>
		</div>
		<div class="position">
			<zk-btn @click="handleEmpower">确认授权登录</zk-btn>
		</div>

		<zk-tost-page v-model="showTost" type="success" bigMsg="恭喜您，已授权信息成功！" smallMsg="问诊数据已采集成功，可在四诊设备上直接问诊数据已采集成功"/>
		<zk-tost-page v-model="showTost2" type="success" bigMsg="未能与设备建立连接！" smallMsg="请重新扫描设备上的二维码">
			<zk-btn @click="handleScanQRCode">进入扫一扫</zk-btn>
		</zk-tost-page>
		<zk-loading v-model="loading">正在授权中…</zk-loading>

	</div>

</template>
<script>
	import { CountDown } from "vant";
	import wx from "weixin-jsapi";
	import { getUserinfo, updateInfo } from "@api/user";
	import { empowerUserInfo, setReidsCustomer, getReidsCustomer } from "@api/system";
	import { removeCookie, getCookie } from "@/util/storage.js";
	export default {
		name: "information",
		data() {
			return {
				info: {
					id: "",
					realName: "",
					age: "",
					sex: 1,
					cardNoStr: "",
					height: "",
					weight: "",
				},				
				deviceId: "",
				wechatId:"",
				showTost: false,
				showTost2: false,
				loading: false,
				runInterval:null,
				time:20
			};
		},
		components: {
			[CountDown.name]: CountDown,
		},
		created() {
			let { deviceId } = this.$route.query;
			if (deviceId) {
				this.deviceId = deviceId;
			}
			let _userInfo = getCookie("userinfo");
			console.log(_userInfo)
			if(_userInfo){
				// this.userinfo = _userInfo;
				this.wechatId = _userInfo.wechatId;
			}

		},
		methods: {

			// 确认授权登录
			handleEmpower() {			
					
				if(!this.deviceId){
					this.$toast("未找到对应设备！")
					return;
				}
				this.loading = true;
				setReidsCustomer({
					deviceId: this.deviceId,
					wechatId: this.wechatId
				}).then((res) => {
					if (res.respCode == 200) {						
						this.ajax_getReidsCustomer();
						this.runInterval = setInterval(this.ajax_getReidsCustomer, 2000);						
					} else {
						this.$toast(res.respMsg);
					}
				}).catch(()=>{
					this.loading = false;
				});			
			},		
			
			ajax_getReidsCustomer() {
				
				if(this.time<=0){
					this.loading = false;
					this.showTost = false;
					this.showTost2 = true;
					clearInterval(this.runInterval);
					return 
				};
				
				this.time = this.time-2;
				getReidsCustomer({
					deviceId: this.deviceId,
					wechatId: this.wechatId
				}).then((res) => {
					this.loading = false;
					if (res.respCode == 200 && res.result.id !== null) {
						this.showTost = true;
						clearInterval(this.runInterval);
					}else{
						// this.showTost2 = true;
						// this.showTost = false;
						// clearInterval(this.runInterval);
					}
				}).catch(()=>{
					this.loading = false;
					this.showTost = false;
					this.showTost2 = true;
					clearInterval(this.runInterval);
				});
			},		
			
			handleScanQRCode() {
				let _this = this;
				console.log(wx)
				wx.scanQRCode({
					needResult: 1,									 // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
					scanType: ["qrCode", "barCode"], 		 // 可以指定扫二维码还是一维码，默认二者都有
					success: function(res) {
			
						var result = res.resultStr;
						let _params = qs.parse(result.split("?")[1]);
						if (_params.deviceId) {
							_this.deviceId = deviceId;
						};
			
						this.loading = false;
						this.showTost = false;
						this.showTost2 = false;
			
					},
					error: function(res) {
						this.$toast("扫码失败！")
					}
				});
			},
			
			// 获取个人信息
			ajax_getUserInfo() {
				getUserinfo().then((res) => {
					if (res.respCode == 200) {
						this.info = res.result;
					} else {
						this.$toast(res.respMsg);
					}
				});
			},
			
			
			
		},
		mounted() {
			this.ajax_getUserInfo();
		},
	};
</script>
<style lang="less" scope>
	.group-block {
		margin: 14px;
		border-bottom: solid 1px #eee;
		margin-bottom: 20px;
	}

	.block-info {
		margin-top: 10px;
		padding: 0 0px 20px 26px;
	}

	.position {
		position: fixed;
		bottom: 15px;
		left: 15px;
		right: 15px;
	}
</style>
